.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: none;
}

.offer-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

}

.offer-icon {
  font-size: 12px;
}



.dropdown-menu {
  position: absolute;
  top: 100%;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.dropdown-item {
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  transition: background 0.2s ease-in-out;
}

.dropdown-item:hover {
  background: #1f1f1f;
}


.logoHeader {
  height: 64px;
}

.language-toggle {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.divtoggle {
  position: fixed;
  right:20px;
  top:20px;
}

.language-display {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #00000090;
  color: #fff;
}

.language-display .arrow {
  margin-left: 5px;
  width: 10px;
  height: 10px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  transition: transform 0.2s ease;
}

.language-display .arrow.up {
  transform: rotate(135deg);
}

.language-display .arrow.down {
  transform: rotate(-45deg);
}

.language-menu {
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #00000090;

  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;

}

.language-menu button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  background: none;
  border: none;
  color: #fff;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 16px; /* Rozmiar tekstu */
}

.language-menu button:hover {
  background-color: #1f1f1f;
}

.language-menu button span {
  font-size: 24px; /* Rozmiar ikony flagi */
  margin-right: 10px; /* Odstęp między flagą a tekstem */
  display: flex;
  align-items: center;
}

/* Styl dla menu w trybie mobilnym */
.menu {
  display: none; /* Domyślnie ukryte */
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
}

.menu-open {
  display: flex; /* Widoczne, gdy menu jest otwarte */
}

.menu-toggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

@media (min-width: 968px) {
  .menu {
    display: flex;
    flex-direction: row;
    position: static;
    background: none;
    padding: 0;
  }

  .menu-toggle {
    display: none; /* Ukryj przycisk na większych ekranach */
  }
}
