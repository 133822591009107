.hero {
  background-color: rgba(0, 0, 0, 0.0);
  padding: 0px 20px;
  text-align: center;
  margin: auto;
  color:fff;
  max-width: 100ch;



}

.hero h2 {
  font-size: 36px;
  margin-bottom: 20px;
  margin-top:0px;
  color: #fff;
  text-shadow: 2px 2px #000000;

}

.hero p {
  font-size: 17px;
  color: #fff;
    text-shadow: 2px 2px #000000;
    font-weight: bold;
}

.mb-5 {
  font-weight: bold;
}

.logoHero {
  width:176px;
  height:176px;
  margin-bottom: 10px;
}



.row {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.col-md {
  flex: 0 0 auto;

  max-width: 220px;
  margin: auto;
}

.mt-1 {
  margin-top: 0px !important;

}



.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: #23232890;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
  background-color: white;
  color: #000;
  border: 1px solid #ffdb00;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
  transform: scale(1.02);

}

.btn:active {
  transform: translateY(2px); 
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}




.buttons-section {
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.buttons-section .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.pt-5 {
  padding: 0rem !important;
}




a {
  color: inherit;
  text-decoration: none;
}


.animation-container {
  position: fixed;

  left: 900px;
  width: 100%;
  height: 100%;
  z-index: -1; /* aby umieścić w tle */
  pointer-events: none;
}

.animation-bg {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover; /* dopasowanie do kontenera */
}


.show {
  display: block;
}

.rotatingLogo {
  display: block;
  margin: 0 auto; /* Centrowanie logo */
  width: 150px; /* Dopasuj rozmiar */
  animation: spin 2s linear infinite; /* Animacja */
}

@keyframes spin {
  0% {
    transform: rotateY(0deg); /* Start obrotu */
  }
  100% {
    transform: rotateY(360deg); /* Pełny obrót */
  }
}
