.kontakt {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 30px;
  margin: auto;
  color: #fff;
  max-width: 100ch;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.kontakt h1 {
  font-size: 2.5rem;
  color: #ffdb00;
  margin-bottom: 20px;
  text-shadow: 2px 2px #000;
  text-align: center;
}

.kontakt .intro {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  line-height: 1.6;
}

.kontakt-info {
  text-align: center;
  margin-bottom: 30px;
}

.kontakt-info h2 {
  font-size: 1.8rem;
  color: #ffdb00;
  text-shadow: 2px 2px #000;
}

.kontakt-info p {
  font-size: 1rem;
  margin: 10px 0;
  color: #fff;
}

.kontakt-formularz {
  max-width: 600px;
  margin: 0 auto;
}

.kontakt-formularz .form-group {
  margin-bottom: 20px;
}

.kontakt-formularz label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffdb00;
  text-shadow: 1px 1px #000;
}

.kontakt-formularz input,
.kontakt-formularz textarea {
  width: 94%;
  padding: 10px;
  margin-top: 5px;
  background-color: #1f1f1f;
  border: 1px solid #ffdb00;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  transition: box-shadow 0.2s;
}

.kontakt-formularz input:focus,
.kontakt-formularz textarea:focus {
  box-shadow: 0px 0px 10px #ffdb00;
  border-color: #ffdb00;
}

.kontakt-formularz button {
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #ffdb00;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.kontakt-formularz button:hover {
  background-color: #e0c200;
  transform: scale(1.02);
}

.kontakt-formularz button:active {
  transform: translateY(2px);
}
