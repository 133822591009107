.uni-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.uni-canvas {
  width: 100%;
  height: 100%;

}

Canvas {
  background-color: #000000; /* W tym miejscu możesz zmienić kolor na czarny */
  min-height: 700px;



}