@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.App {
  font-family: Inter;
}

section {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .oferta-sekcja-1 {
    flex-direction: column;
  }

  .oferta-sekcja-1 .tekst {
    margin-right: 0;
    margin-bottom: 20px;
  }
}