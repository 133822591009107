.oferta-sekcja-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 40px 40px;

  background-color: rgba(35, 35, 35, 0.8); /* Dopasowanie do stylu projektu */
  color: #fff; /* Tekst w kolorze białym */
  border-radius: 10px;
  margin: 20px auto;
  max-width: 1200px;
  height: auto;
}

/* Kontener dla przycisków pod sekcją */
.buttons-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

/* Styl dla przycisków sekcji */
.section-btn {
  background-color: #ffdb00;
  border: none;
  padding: 15px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.section-btn:hover {
  background-color: #e5c100;
}

/* Przycisk DEMO - obok zdjęcia */
.demo-btn {
  background-color: #ff5733;
  margin-top:20px;
  color: white;
}

.demo-btn:hover {
  background-color: #cc4629;
}


.tekst {
  flex: 1;
  margin-right: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Efekt cienia */
}

.tekst h2 {
  font-size: 32px;
  color: #ffdb00; /* Żółty kolor dla nagłówków */
}

.tekst p {
  font-size: 18px;
  line-height: 1.6;
}

.obrazek {
  flex: 1;
  text-align: center;

}

.obrazek img {
  min-width: 100%;
  max-height: 400px !important;
  object-fit: cover; /* Zapewnia, że obraz nie będzie zdeformowany */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
}


@media (max-width: 768px) {
  .oferta-sekcja-1 {
    flex-direction: column;
    text-align: center;
  }

  .tekst {
    margin-right: 0;
    margin-bottom: 20px;
  }
}


.opis {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-family: 'Inter', sans-serif;
  padding: 20px;
  max-width: 100ch;
  margin: auto;
  text-align: left;
}

.opis h1, .opis h2 {
  color: #ffdb00;
  text-align: center;
  text-shadow: 2px 2px #000;
  margin-bottom: 20px;
  font-size: 2.4rem; /* Powiększony rozmiar nagłówków */
}

.opis h3 {
  font-size: 1.8rem; /* Powiększenie dla podnagłówków */
  color: #f8f9fa;
  text-shadow: 1px 1px #000;
  margin-top: 20px;
}

.opis ul {
  list-style-type: none;
  padding: 0;
}

.opis ul li {
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  padding: 15px; /* Zwiększenie odstępu wewnętrznego */
  border-left: 4px solid #ffdb00;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 1.2rem; /* Powiększenie tekstu listy */
  line-height: 1.6; /* Większy odstęp między wierszami */
}

.opis p {
  margin-bottom: 15px;
  font-size: 1.2rem; /* Powiększony rozmiar tekstu paragrafów */
  line-height: 1.8; /* Większy odstęp między wierszami */
}

.section {
  margin-bottom: 30px;
}


.oferta-sekcja-2 {
  background-color: rgba(35, 35, 35, 0.8); /* Dopasowanie do stylu projektu */
  color: #fff; /* Tekst w kolorze białym */
  border-radius: 10px;
  margin: 20px auto;
  padding: 40px 40px;
  max-width: 1200px;
  text-align: left;
}

.oferta-sekcja-2 h2 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
  color: #ffdb00; /* Żółty kolor dla nagłówków */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.oferta-sekcja-2 h1 {
  font-size: 42px;
  margin-top: 0px !important;
  text-align: center;
  color: #ffdb00; /* Żółty kolor dla nagłówków */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.oferta-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.oferta-tekst,
.oferta-funkcje {
  flex: 1;
  margin: 10px;
  max-width: 45%; /* Maksymalna szerokość każdej kolumny */
}

.oferta-tekst h3,
.oferta-funkcje h3 {
  font-size: 20px;
  color: #ffdb00; /* Żółty kolor dla podtytułów */
  margin-bottom: 10px;
}

.oferta-tekst p {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.6;
}

.oferta-funkcje ul {
  list-style: none;
  padding: 0;
}

.oferta-funkcje ul li {
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
}

.oferta-funkcje ul li::before {
  content: '•';
  color: #ffdb00; /* Żółty punkt */
  font-size: 24px;
  position: absolute;
  left: -20px;
  top: 0;
}

@media (max-width: 768px) {
  .oferta-content {
    flex-direction: column;
  }

  .oferta-tekst,
  .oferta-funkcje {
    max-width: 100%;
    margin: 10px 0;
  }
}


.oferta-sekcja-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 40px;
  background-color: rgba(35, 35, 35, 0.8); /* Dopasowanie do stylu projektu */
  color: #fff; /* Tekst w kolorze białym */
  border-radius: 10px;
  margin: 20px auto;
  max-width: 1200px;
}

.obrazek {
  flex: 1;
  text-align: center;
  margin-right: 20px; /* Odstęp między obrazkiem a tekstem */
}

.obrazek img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6); /* Dodanie cienia pod obrazkiem */
}

.tekst {
  flex: 1;
  margin-left: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Efekt cienia */
}

.tekst h2 {
  font-size: 32px;
  margin-bottom: 15px;
  color: #ffdb00; /* Żółty kolor dla nagłówków */
}

.tekst h3 {
  font-size: 20px;
  margin-top: 20px;
  color: #ffdb00; /* Żółty kolor dla podtytułów */
}

.tekst p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .oferta-sekcja-3 {
    flex-direction: column; /* Ustawienie kolumny na mniejszych ekranach */
    text-align: center;
  }

  .obrazek {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .tekst {
    margin-left: 0;
  }
}
